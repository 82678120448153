<template>
  <div>
    <h1 :class="$style.pageTitle">保険証券</h1>
    <div class="row">
      <div class="col-lg-12">
        <section class="card">
          <div class="card-body">
            <div v-if="insurancePolicyLoading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!insurancePolicyLoading">
              <p class="text-right">
                <a-button :loading="confirmInsurancePolicyLoading" type="primary" @click="onMakeInsurancePolicy" :class="$style.filledBtn">
                  作成
                </a-button>
              </p>
              <hr style="border-width: 3px;">

              <div v-for="item in insurancePolicies" :key="item.id" style="margin-bottom: 50px;">
                <div class="clearfix">
                  <h2 class="pull-left">{{ item.name }}</h2>
                  <p class="pull-right">
                    <a-button type="primary" @click="addInsurancePolicyProduct(item.id)" style="margin-right: 10px;">商品追加</a-button>
                    <a-button @click="updateInsurancePolicy(item.id, item.name)">編集</a-button>
                  </p>
                </div>
                <a-list
                  item-layout="horizontal"
                  :data-source="item.insurance_policy_products.data"
                  :pagination="false"
                >
                  <a-list-item slot="renderItem" slot-scope="itemProduct">
                    <a slot="actions"><a-button @click="updateInsurancePolicyProduct(item.id, itemProduct.id)">編集</a-button></a>
                    <a-list-item-meta>
                      <div slot="title" class="list-name">
                        <p>{{ itemProduct.name }}</p>
                      </div>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
                <hr>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <a-modal
      :title="modalText"
      :visible="visibleInsurancePolicy"
      @cancel="handleCancelInsurancePolicy"
    >
      <div>
        <a-form-model
          ref="insurancePolicyRef"
          :rules="insurancePolicyRules"
          :model="insurancePolicyForm"
        >
          <a-form-model-item ref="name" label="名前" prop="name">
            <a-input
              v-model="insurancePolicyForm.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-left">
            <a-button v-if="targetInsurancePolicyId" key="submit" type="danger" :loading="confirmInsurancePolicyLoading" @click="handleDeleteInsurancePolicy">
              削除
            </a-button>
          </div>
          <div class="float-right">
            <a-button key="back" @click="handleCancelInsurancePolicy">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="confirmInsurancePolicyLoading" @click="handleOkInsurancePolicy">
              {{ modalText }}
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="modalText"
      :visible="visibleInsurancePolicyProduct"
      @cancel="handleCancelInsurancePolicyProduct"
    >
      <div>
        <a-form-model
          ref="insurancePolicyProductRef"
          :rules="insurancePolicyProductRules"
          :model="insurancePolicyProductForm"
        >
          <a-form-model-item ref="name" label="名前" prop="name">
            <a-input
              v-model="insurancePolicyProductForm.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
            />
          </a-form-model-item>

          <a-config-provider :locale="locale">
            <a-form-model-item ref="dateStart" label="契約期間（開始）" prop="dateStart">
              <a-date-picker placeholder="契約期間（開始）" v-model="dateStart" :allowClear="false" />
            </a-form-model-item>
          </a-config-provider>

          <a-config-provider :locale="locale">
            <a-form-model-item ref="dateEnd" label="契約期間（終了）" prop="dateEnd">
              <a-date-picker placeholder="契約期間（終了）" v-model="dateEnd" :allowClear="false" />
            </a-form-model-item>
          </a-config-provider>

          <a-form-model-item ref="price" label="支払保険料" prop="price">
            <a-input-number v-model="insurancePolicyProductForm.price" />
          </a-form-model-item>

          <a-form-model-item ref="price_previous_year" label="前年度の支払保険金" prop="price_previous_year">
            <a-input-number v-model="insurancePolicyProductForm.price_previous_year" />
          </a-form-model-item>

          <a-form-model-item ref="discount_increase_rate" label="割引率" prop="discount_increase_rate">
            <a-input-number v-model="insurancePolicyProductForm.discount_increase_rate" />
          </a-form-model-item>

          <a-form-model-item ref="number_of_contracts" label="契約台数" prop="number_of_contracts">
            <a-input-number v-model="insurancePolicyProductForm.number_of_contracts" />
          </a-form-model-item>

          <a-form-model-item ref="insurance_company" label="保険会社" prop="insurance_company">
            <a-input
              v-model="insurancePolicyProductForm.insurance_company"
              @blur="
                () => {
                  $refs.insurance_company.onFieldBlur()
                }
              "
            />
          </a-form-model-item>

          <a-form-model-item ref="insurance_agency" label="保険代理店" prop="insurance_agency">
            <a-input
              v-model="insurancePolicyProductForm.insurance_agency"
              @blur="
                () => {
                  $refs.insurance_agency.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-left">
            <a-button v-if="targetInsurancePolicyProductId" key="submit" type="danger" :loading="confirmInsurancePolicyLoading" @click="handleDeleteInsurancePolicyProduct">
              削除
            </a-button>
          </div>
          <div class="float-right">
            <a-button key="back" @click="handleCancelInsurancePolicyProduct">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="confirmInsurancePolicyLoading" @click="handleOkInsurancePolicyProduct">
              {{ modalText }}
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import jaJa from 'ant-design-vue/es/locale/ja_JP'

export default {
  data() {
    return {
      locale: jaJa,
      insurancePolicyLoading: true,
      confirmInsurancePolicyLoading: false,
      insurancePolicies: [],
      modalText: '作成',
      visibleInsurancePolicy: false,
      visibleInsurancePolicyProduct: false,
      targetInsurancePolicyId: 0,
      targetInsurancePolicyProductId: 0,
      insurancePolicyForm: {
        name: '',
      },
      insurancePolicyRules: {
        name: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 1023, message: '1023文字までです。', trigger: 'blur' },
        ],
      },
      insurancePolicyProductForm: {
        name: '',
        date_start: '',
        date_end: '',
        price: null,
        price_previous_year: null,
        discount_increase_rate: null,
        number_of_contracts: null,
        insurance_company: '',
        insurance_agency: '',
      },
      insurancePolicyProductRules: {
        name: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 1023, message: '1023文字までです。', trigger: 'blur' },
        ],
        date_start: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        date_end: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        price: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        price_previous_year: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        discount_increase_rate: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        number_of_contracts: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        insurance_company: [
          { max: 1023, message: '1023文字までです。', trigger: 'blur' },
        ],
        insurance_agency: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 1023, message: '1023文字までです。', trigger: 'blur' },
        ],
      },
      dateStart: moment(),
      dateEnd: moment(),
    }
  },
  watch: {
    dateStart: function(val) {
      if (val) this.insurancePolicyProductForm.date_start = val.format('YYYY-MM-DD')
      else this.insurancePolicyProductForm.date_start = null
    },
    dateEnd: function(val) {
      if (val) this.insurancePolicyProductForm.date_end = val.format('YYYY-MM-DD')
      else this.insurancePolicyProductForm.date_end = null
    },
  },
  created() {
    const result = Vue.prototype.$api.send('get', 'insurance_policies')
    result.then(response => {
      this.insurancePolicies = response
      this.insurancePolicyLoading = false
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 保険証券の取得に失敗しました。',
        })
        this.insurancePolicyLoading = false
      })
  },
  methods: {
    handleCancelInsurancePolicy() { this.visibleInsurancePolicy = false },
    handleCancelInsurancePolicyProduct() { this.visibleInsurancePolicyProduct = false },
    updateInsurancePolicy(id, name) {
      this.visibleInsurancePolicy = true
      this.targetInsurancePolicyId = id
      this.insurancePolicyForm.name = name
      this.modalText = '更新'
    },
    onMakeInsurancePolicy() {
      this.visibleInsurancePolicy = true
      this.targetInsurancePolicyId = 0
      this.insurancePolicyForm.name = ''
      this.modalText = '作成'
    },
    handleOkInsurancePolicy() {
      const _this = this
      this.confirmInsurancePolicyLoading = true
      var actionType = 'post'
      var bodyData = {
        name: this.insurancePolicyForm.name,
      }
      if (this.targetInsurancePolicyId !== 0) {
        actionType = 'put'
        bodyData = {
          id: this.targetInsurancePolicyId,
          name: this.insurancePolicyForm.name,
        }
      }

      this.$refs.insurancePolicyRef.validate(valid => {
        const sendInsurancePolicy = Vue.prototype.$api.send(actionType, 'insurance_policies', bodyData)
        sendInsurancePolicy.then(response => {
          _this.$notification['success']({
            message: '保険証券を更新しました。',
          })
          if (this.targetInsurancePolicyId !== 0) {
            var targetUpdateInsurancePolicy = this.insurancePolicies.find(e => e.id === this.targetInsurancePolicyId)
            targetUpdateInsurancePolicy.name = this.insurancePolicyForm.name
          } else {
            this.insurancePolicies.push(response)
          }
          this.visibleInsurancePolicy = false
          this.confirmInsurancePolicyLoading = false
        })
          .catch(error => {
            if (error.status === 403) {
              _this.$notification['error']({
                message: error.data.data,
              })
            } else {
              for (var key in error.data.errors) {
                _this.$refs[key].validateState = 'error'
                _this.$refs[key].validateMessage = error.data.errors[key]
              }
            }
            this.confirmInsurancePolicyLoading = false
            console.log(error)
          })
      })
    },
    handleDeleteInsurancePolicy() {
      const _this = this
      this.confirmInsurancePolicyLoading = true
      const send = Vue.prototype.$api.send('delete', 'insurance_policies', { id: this.targetInsurancePolicyId })
      send.then(response => {
        _this.$notification['success']({
          message: '保険証券を削除しました。',
        })
        this.insurancePolicies = this.insurancePolicies.filter(e => e.id !== _this.targetInsurancePolicyId)
        this.visibleInsurancePolicy = false
        this.confirmInsurancePolicyLoading = false
      })
        .catch(error => {
          _this.$notification['error']({
            message: error.data.data,
          })
          this.visibleInsurancePolicy = false
          this.confirmInsurancePolicyLoading = false
          console.log(error)
        })
    },

    addInsurancePolicyProduct(id) {
      this.visibleInsurancePolicyProduct = true
      this.targetInsurancePolicyId = id
      this.insurancePolicyProductForm.name = ''
      this.insurancePolicyProductForm.date_start = this.dateStart.format('YYYY-MM-DD')
      this.insurancePolicyProductForm.date_end = this.dateEnd.format('YYYY-MM-DD')
      this.insurancePolicyProductForm.price = null
      this.insurancePolicyProductForm.price_previous_year = null
      this.insurancePolicyProductForm.discount_increase_rate = null
      this.insurancePolicyProductForm.number_of_contracts = null
      this.insurancePolicyProductForm.insurance_company = ''
      this.insurancePolicyProductForm.insurance_agency = ''
      this.modalText = '追加'
    },
    updateInsurancePolicyProduct(id, productId) {
      this.visibleInsurancePolicyProduct = true
      this.targetInsurancePolicyId = id
      this.targetInsurancePolicyProductId = productId

      var targetUpdateInsurancePolicy = this.insurancePolicies.find(e => e.id === id)
      var targetUpdateInsurancePolicyProduct = targetUpdateInsurancePolicy.insurance_policy_products.data.find(e => e.id === productId)
      this.insurancePolicyProductForm.name = targetUpdateInsurancePolicyProduct.name
      this.insurancePolicyProductForm.date_start = targetUpdateInsurancePolicyProduct.date_start
      this.insurancePolicyProductForm.date_end = targetUpdateInsurancePolicyProduct.date_end
      this.insurancePolicyProductForm.price = targetUpdateInsurancePolicyProduct.price
      this.insurancePolicyProductForm.price_previous_year = targetUpdateInsurancePolicyProduct.price_previous_year
      this.insurancePolicyProductForm.discount_increase_rate = targetUpdateInsurancePolicyProduct.discount_increase_rate
      this.insurancePolicyProductForm.number_of_contracts = targetUpdateInsurancePolicyProduct.number_of_contracts
      this.insurancePolicyProductForm.insurance_company = targetUpdateInsurancePolicyProduct.insurance_company
      this.insurancePolicyProductForm.insurance_agency = targetUpdateInsurancePolicyProduct.insurance_agency
      this.modalText = '更新'
      const targetDateStart = moment(this.insurancePolicyProductForm.date_start, 'YYYY-MM-DD')
      const targetDateEnd = moment(this.insurancePolicyProductForm.date_end, 'YYYY-MM-DD')
      this.dateStart = targetDateStart
      this.dateEnd = targetDateEnd
    },
    handleOkInsurancePolicyProduct() {
      const _this = this
      this.confirmInsurancePolicyLoading = true
      var actionType = 'post'
      var bodyData = {
        insurance_policy_id: this.targetInsurancePolicyId,
        name: this.insurancePolicyProductForm.name,
        date_start: this.insurancePolicyProductForm.date_start,
        date_end: this.insurancePolicyProductForm.date_end,
        price: this.insurancePolicyProductForm.price,
        price_previous_year: this.insurancePolicyProductForm.price_previous_year,
        discount_increase_rate: this.insurancePolicyProductForm.discount_increase_rate,
        number_of_contracts: this.insurancePolicyProductForm.number_of_contracts,
        insurance_company: this.insurancePolicyProductForm.insurance_company,
        insurance_agency: this.insurancePolicyProductForm.insurance_agency,
      }
      if (this.targetInsurancePolicyProductId !== 0) {
        actionType = 'put'
        bodyData = {
          id: this.targetInsurancePolicyProductId,
          name: this.insurancePolicyProductForm.name,
          date_start: this.insurancePolicyProductForm.date_start,
          date_end: this.insurancePolicyProductForm.date_end,
          price: this.insurancePolicyProductForm.price,
          price_previous_year: this.insurancePolicyProductForm.price_previous_year,
          discount_increase_rate: this.insurancePolicyProductForm.discount_increase_rate,
          number_of_contracts: this.insurancePolicyProductForm.number_of_contracts,
          insurance_company: this.insurancePolicyProductForm.insurance_company,
          insurance_agency: this.insurancePolicyProductForm.insurance_agency,
        }
      }

      this.$refs.insurancePolicyProductRef.validate(valid => {
        const sendInsurancePolicyProduct = Vue.prototype.$api.send(actionType, 'insurance_policies/insurance_policy_products', bodyData)
        sendInsurancePolicyProduct.then(response => {
          _this.$notification['success']({
            message: '保険証券商品を更新しました。',
          })
          var targetUpdateInsurancePolicy = this.insurancePolicies.find(e => e.id === this.targetInsurancePolicyId)
          if (this.targetInsurancePolicyProductId !== 0) {
            var targetUpdateInsurancePolicyProduct = targetUpdateInsurancePolicy.insurance_policy_products.data.find(e => e.id === this.targetInsurancePolicyProductId)
            targetUpdateInsurancePolicyProduct.name = this.insurancePolicyProductForm.name
            targetUpdateInsurancePolicyProduct.date_start = this.insurancePolicyProductForm.date_start
            targetUpdateInsurancePolicyProduct.date_end = this.insurancePolicyProductForm.date_end
            targetUpdateInsurancePolicyProduct.price = this.insurancePolicyProductForm.price
            targetUpdateInsurancePolicyProduct.price_previous_year = this.insurancePolicyProductForm.price_previous_year
            targetUpdateInsurancePolicyProduct.discount_increase_rate = this.insurancePolicyProductForm.discount_increase_rate
            targetUpdateInsurancePolicyProduct.number_of_contracts = this.insurancePolicyProductForm.number_of_contracts
            targetUpdateInsurancePolicyProduct.insurance_company = this.insurancePolicyProductForm.insurance_company
            targetUpdateInsurancePolicyProduct.insurance_agency = this.insurancePolicyProductForm.insurance_agency
          } else {
            targetUpdateInsurancePolicy.insurance_policy_products.data.push(response)
          }
          this.visibleInsurancePolicyProduct = false
          this.confirmInsurancePolicyLoading = false
        })
          .catch(error => {
            if (error.status === 403) {
              _this.$notification['error']({
                message: error.data.data,
              })
            } else {
              for (var key in error.data.errors) {
                _this.$refs[key].validateState = 'error'
                _this.$refs[key].validateMessage = error.data.errors[key]
              }
            }
            this.confirmInsurancePolicyLoading = false
            console.log(error)
          })
      })
    },
    handleDeleteInsurancePolicyProduct() {
      const _this = this
      this.confirmInsurancePolicyLoading = true
      const send = Vue.prototype.$api.send('delete', 'insurance_policies/insurance_policy_products', { id: this.targetInsurancePolicyProductId })
      send.then(response => {
        _this.$notification['success']({
          message: '保険証券商品を削除しました。',
        })
        var targetUpdateInsurancePolicy = this.insurancePolicies.find(e => e.id === this.targetInsurancePolicyId)
        targetUpdateInsurancePolicy.insurance_policy_products.data = targetUpdateInsurancePolicy.insurance_policy_products.data.filter(e => e.id !== _this.targetInsurancePolicyProductId)
        this.visibleInsurancePolicyProduct = false
        this.confirmInsurancePolicyLoading = false
      })
        .catch(error => {
          _this.$notification['error']({
            message: error.data.data,
          })
          this.visibleInsurancePolicyProduct = false
          this.confirmInsurancePolicyLoading = false
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
.list-name {
  font-size: 1.5rem;
}
</style>
